import ChecklistCheckboxInput from "./ChecklistCheckboxInput"
import ChecklistTextfieldInput from "./ChecklistTextfieldInput";
import ChecklistImageInput from "./ChecklistImageInput";
import {
  Box,
  Divider,
  ListItem,
  ListItemText
} from "@mui/material";



const TYPE_CHECKBOX = 0;
const TYPE_TEXTFIELD = 1;
const TYPE_IMAGE = 2;

const ChecklistInput = ({ checklistID, stepID, input, onUpdate, disabled }) => {

  const Content = () => {
    switch (input?.input_type) {
      case TYPE_CHECKBOX:
        return <ChecklistCheckboxInput onUpdate={onUpdate} checklistID={checklistID} stepID={stepID} input={input} disabled={disabled} />
      case TYPE_TEXTFIELD:
        return <ChecklistTextfieldInput onUpdate={onUpdate} checklistID={checklistID} stepID={stepID} input={input} disabled={disabled} />
      case TYPE_IMAGE:
        return <ChecklistImageInput onUpdate={onUpdate} checklistID={checklistID} stepID={stepID} input={input} disabled={disabled} />
      default:
        return <div></div>
    }
  }

  return (
    <Box>

      <ListItem>
        <ListItemText
          primary={input.title}
          secondary={input.required ? "Required" : ""}
        />
        <Content />
      </ListItem>
      <Divider component="li" />
    </Box>
  )
}

export default ChecklistInput;